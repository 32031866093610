import {UseFetchOptions} from "#app";
import {useUser} from "~/stores/UserStore";

export function useHttp() {

    const basePath = useRuntimeConfig().public.apiUrl

    const get = async (url: string, headers?: any) => {
        return request('GET', url, null, headers);
    }

    const deleteRequest = async (url: string) => {
        return request('DELETE', url);
    }

    const patch = async (url: string, body: any) => {
        return request('PATCH', url, body);
    }

    const post = async (url: string, body: any, headers?: any) => {
        return request('POST', url, body, headers);
    }

    const request = async (method: 'POST' | 'GET' | 'PATCH' | 'DELETE', url: string, body?: any, headers: any = {}) => {

        let options: any = {method: method, headers: headers};

        if (body) {
            options = {...options, body: body}
        }

        if (useRouter().currentRoute.value.path.indexOf('/admin') >= 0 || useRouter().currentRoute.value.path.indexOf('/login') >= 0) {
            useUser().token = localStorage.getItem('token')
        } else if (useRouter().currentRoute.value.path.indexOf('/collaborators') >= 0) {
            useUser().token = localStorage.getItem('collaborator_token')
        } else {
            useUser().token = localStorage.getItem('survey_token')
        }

        options = {...options, headers: {...options.headers, Authorization: 'Bearer ' + useUser().token}}


        let {data, pending, error} = await useFetch(basePath + url, options);
        return {data, error, pending};

    }

    return {get, post, patch, deleteRequest}

}
