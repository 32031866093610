import { QuestionType } from "./QuestionType";

export class Question {
    uuid: string
    title: string
    type: QuestionType
    description: string
    section_uuid: string
    inverted: string
    template: ReducedScaleQuestionTemplate | ExtendedScaleQuestionTemplate | FreeFormQuestionTemplate | SelectQuestionTemplate

    constructor({uuid, title, type, description, section_uuid, template}: { uuid: string, title: string, type: QuestionType, description: string, section_uuid: string, template: ReducedScaleQuestionTemplate | ExtendedScaleQuestionTemplate | FreeFormQuestionTemplate | SelectQuestionTemplate }) {
        this.uuid = uuid
        this.title = title
        this.type = type
        this.description = description
        this.section_uuid = section_uuid
        this.template = template
    }
}


export class ReducedScaleQuestionTemplate {
}

export class ExtendedScaleQuestionTemplate {
    right_label: string
    left_label: string
    constructor({right_label, left_label}: { right_label: string, left_label: string }) {
        this.right_label = right_label
        this.left_label = left_label
    }
}

export class FreeFormQuestionTemplate {

}

export class SelectQuestionTemplate {
    labels: Array<string>
    multiple: boolean

    constructor({labels, multiple}: { label: Array<string>, multiple: boolean }) {
        this.labels = labels
        this.multiple = multiple
    }
}
