import type Form from "~/utils/form/Form";
import type { FieldOptions } from "~/utils/form/fields/FormField";
import  { FormField } from "~/utils/form/fields/FormField";


export class FormFormField extends FormField {
  forms: Array<Form> = [];

  constructor(
    public key: string,
    public label: string,
    public formComponent: string,
    public value: any,
    public validators: Function[],
    public type: string,
    public form: Form,
    public options: FieldOptions = {}
  ) {
    super(key, formComponent, label, value, validators, type, options);
    value.forEach((v)=>{
      let initForm = ref(form() as Form);
      initForm.value.setValues(v)
      this.forms.push(initForm)
    })
  }

  public errors:any[] = [];

  public reset(): void {
    this.value = [];
    this.errors = [];
  }

  public async validate() {
    this.errors = [];
    this.forms.map( async (f:Form) => {
      await f.value.isValid();
      if (f.errors) {
        this.errors.push(f.errors);
      }
    });
  }

  public removeForm(form) {
    this.forms = this.forms.filter((_form) => !this.isEqual(_form, form));
  }

  public isEqual(form1, form2) {
    return (
      JSON.stringify(form1.getRawValues()) ===
      JSON.stringify(form2.getRawValues())
    );
  }
}
