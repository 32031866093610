//import Proposal from "./Proposal";

export class Cohort {
    uuid: string | undefined
    title: string
    description: string
    survey_uuid: string
    parent_uuid: string | undefined
    children:Cohort[]

    constructor({ uuid, title, description, survey_uuid, parent_uuid, children }: { uuid?: string, title: string, description: string, survey_uuid: string, parent_uuid?:string, children:Cohort[] }) {
        this.uuid = uuid
        this.title = title
        this.description = description
        this.survey_uuid = survey_uuid
        this.parent_uuid = parent_uuid
    }

}
