import dayjs from 'dayjs'
export class Validators {
    static required(value: string|number):string|null  {
        return value !== null && value !== "" ? null : "error.required";
    }

    static moreThanZero(value: number):string|null {
        if (value <= 0) {
            return  'error.moreThanZero';
        }

        return null;
    }

    static email(value: any, errorText: Array<string>):string|null {

        const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/;

        if (!regex.test(value)) {
            return 'error.email'
        }

        return null;
    }

    static year(value: any, errorText: Array<string>):string|null {
        const regex = /^[0-9]{4}$/;

        if (!regex.test(value)) {
            return 'error.year'
        }
        return null;
    }

}
