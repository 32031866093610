

  import Form from "~/utils/form/Form";
  import { FieldTypes, FormField } from "~/utils/form/fields/FormField";
  import { Validators } from "~/utils/form/Validators";
  import { SelectFormField } from "~/utils/form/fields/SelectFormField";
  import { Cohort } from "~/model/Cohort";

  export default function CohortForm(cohort?:Cohort): Ref<Form> {
      const form = ref(new Form());
      form.value.controls.push(new FormField('title', FieldTypes.text, 'label.title', cohort?.title ?? null, [Validators.required], FieldTypes.text, {}))
      form.value.controls.push(new FormField('description', FieldTypes.text, 'label.description', cohort?.description ?? null, [Validators.required], FieldTypes.text, {}))
      form.value.controls.push(new FormField('survey_uuid', FieldTypes.text, 'label.survey_uuid', cohort?.survey_uuid ?? null, [Validators.required], FieldTypes.text, {}))
      form.value.controls.push(new SelectFormField('parent_uuid', FieldTypes.text, 'label.parent_uuid', cohort?.parent_uuid ?? null, [], FieldTypes.text, [], {}))
      return form;
  }
