import type {FieldOptions} from "~/utils/form/fields/FormField";
import {FormField} from "~/utils/form/fields/FormField";
import FormChoice from "~/utils/form/Form";


export class SelectFormField extends FormField  {

    public filteredChoices: Array<FormChoice> = [];
    constructor(
        public key: string,
        public formComponent: string,
        public label: string,
        public value: any,
        public validators: Function[] = [],
        public type: string,
        public _choices: Array<FormChoice>,
        public options: FieldOptions = {}
    ) {
        super(key, formComponent, label, value, validators, type, options);
    }

    get choices():Array<FormChoice>{
        if(this.filteredChoices.length > 0)
        {
            return this.filteredChoices;
        }
        return this._choices;
    }

    set choices(choices:Array<FormChoice>){
        this._choices = choices;
    }

    filterChoices(values:Array<FormChoice>){
        let filteredChoices: Array<FormChoice> = [];
        this._choices.forEach(choice=>{
            if(values.includes(choice.value)){
                filteredChoices.push(choice)
            }
        })
        this.filteredChoices = filteredChoices
        this.value = filteredChoices[0].value;
    }
}
