

  import Form from "~/utils/form/Form";
  import { FieldTypes, FormField } from "~/utils/form/fields/FormField";
  import { Validators } from "~/utils/form/Validators";
  import { Section } from "~/model/Section";

  export default function SectionForm(section?:Section): Ref<Form> {
      const form = ref(new Form());

      form.value.controls.push(new FormField('title', FieldTypes.text, 'label.title', section?.title ?? null, [Validators.required], FieldTypes.text, {}))
      form.value.controls.push(new FormField('description', FieldTypes.text, 'label.description', section?.description ?? "", [], FieldTypes.text, {}))
      form.value.controls.push(new FormField('survey_uuid', FieldTypes.text, 'label.survey_uuid', section?.survey_uuid ?? null, [Validators.required], FieldTypes.text, {}))
      form.value.controls.push(new FormField('image', FieldTypes.text, 'label.image', section?.image ?? null, [Validators.required], FieldTypes.text, {}))

      return form;
  }
