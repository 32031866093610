import Form from "~/utils/form/Form";
import {FieldTypes, FormField} from "~/utils/form/fields/FormField";
import {Validators} from "~/utils/form/Validators";
import {QuestionType} from "@/model/QuestionType";
import {SelectFormField} from "~/utils/form/fields/SelectFormField";
import {Question} from "@/model/Question";
import {FormFormField} from "~/utils/form/fields/FormFormField";
import LabelsFormComponent from "@/components/form/LabelsFormComponent.vue";
import TextInput from "~/components/form/fields/TextInput.vue";

export default function QuestionForm(question?: Question): Ref<Form> {
    const form = ref(new Form());

    const typeChoices = [
        {label: 'label.reducedScale', value: QuestionType.reducedScale},
        {label: 'label.extendedScale', value: QuestionType.extendedScale},
        {label: 'label.select', value: QuestionType.select},
        {label: 'label.freeForm', value: QuestionType.freeForm}
    ]

    form.value.controls.push(new FormField('title', FieldTypes.text, 'label.title', question?.title ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new SelectFormField('type', FieldTypes.select, 'label.type', question?.type ?? null, [Validators.required], FieldTypes.select, typeChoices, {}))
    form.value.controls.push(new FormField('description', FieldTypes.text, 'label.description', question?.description ?? "", [], FieldTypes.text, {}))
    form.value.controls.push(new FormField('section_uuid', FieldTypes.text, 'label.section_uuid', question?.section_uuid ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new FormField('multiple', FieldTypes.boolean, 'label.multiple', question?.template?.multiple ?? false, [Validators.required], FieldTypes.boolean, {}))

    let labels = [];
    if (question?.template?.labels) {
        labels = question?.template?.labels.map((l, i) => {
            return {label: l, position: i};
        })

    }

    form.value.controls.push(new FormFormField('labels', 'label.labels', LabelsFormComponent, labels ?? [], [Validators.required], FieldTypes.array, LabelsForm, {
        formComponent: LabelsFormComponent,
        sortableField: 'position'
    }))

    form.value.controls.push(new FormField('left_label', FieldTypes.text, 'label.left_label', question?.template?.left_label ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new FormField('right_label', FieldTypes.text, 'label.right_label', question?.template?.right_label ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new FormField('inverted', FieldTypes.boolean, 'label.inverted', question?.inverted ?? null, [], FieldTypes.boolean, {}))

    return form;
}

export function LabelsForm(value?: string): Ref<Form> {
    const form = ref(new Form());
    form.value.controls.push(new FormField('label', FieldTypes.text, 'label.label', value ?? null, [Validators.required], FieldTypes.text, {formComponent: TextInput}))
    form.value.controls.push(new FormField('position', FieldTypes.number, 'label.position', value ?? null, [Validators.required], FieldTypes.number, {formComponent: TextInput}))
    return form;
}
