import { default as create_45account2ledSySRQ0Meta } from "/home/denis/sources/ismat/client/pages/admin/create-account.vue?macro=true";
import { default as indexOSiC3KhSnhMeta } from "/home/denis/sources/ismat/client/pages/admin/index.vue?macro=true";
import { default as indexp1xXuMkHUDMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/index.vue?macro=true";
import { default as indexh0Ao6e5ubuMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/section/[sectionId]/index.vue?macro=true";
import { default as indexgngXBxMe28Meta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/section/[sectionId]/question/[questionId]/index.vue?macro=true";
import { default as _91cohortId_93aK5PeDDn0HMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/diffusion/cohorts/[cohortId].vue?macro=true";
import { default as indexzQnQBSAVTLMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/diffusion/index.vue?macro=true";
import { default as accessx1duDUATn3Meta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/access.vue?macro=true";
import { default as indexC4L2vgMRtWMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/index.vue?macro=true";
import { default as _91sampleId_93X1ZJSwetITMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/samples/edit/[sampleId].vue?macro=true";
import { default as indexSbvFyQNjFxMeta } from "/home/denis/sources/ismat/client/pages/collaborators/index.vue?macro=true";
import { default as indexHyP0IpxMOrMeta } from "/home/denis/sources/ismat/client/pages/collaborators/survey/[surveyId]/results/index.vue?macro=true";
import { default as _91sampleId_93aw3QFEjiD8Meta } from "/home/denis/sources/ismat/client/pages/collaborators/survey/[surveyId]/results/samples/edit/[sampleId].vue?macro=true";
import { default as indexZ5iptvU8XiMeta } from "/home/denis/sources/ismat/client/pages/index.vue?macro=true";
import { default as loginTyMAxjhfbSMeta } from "/home/denis/sources/ismat/client/pages/login.vue?macro=true";
import { default as indexTj3nGIzPR1Meta } from "/home/denis/sources/ismat/client/pages/survey/[surveyId]/index.vue?macro=true";
import { default as _91sectionId_93Um0Uvm7AcRMeta } from "/home/denis/sources/ismat/client/pages/survey/[surveyId]/section/[sectionId].vue?macro=true";
import { default as validationK7bbYw1J0cMeta } from "/home/denis/sources/ismat/client/pages/survey/[surveyId]/validation.vue?macro=true";
export default [
  {
    name: create_45account2ledSySRQ0Meta?.name ?? "admin-create-account",
    path: create_45account2ledSySRQ0Meta?.path ?? "/admin/create-account",
    meta: create_45account2ledSySRQ0Meta || {},
    alias: create_45account2ledSySRQ0Meta?.alias || [],
    redirect: create_45account2ledSySRQ0Meta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/create-account.vue").then(m => m.default || m)
  },
  {
    name: indexOSiC3KhSnhMeta?.name ?? "admin",
    path: indexOSiC3KhSnhMeta?.path ?? "/admin",
    meta: indexOSiC3KhSnhMeta || {},
    alias: indexOSiC3KhSnhMeta?.alias || [],
    redirect: indexOSiC3KhSnhMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexp1xXuMkHUDMeta?.name ?? "admin-survey-surveyId-creation",
    path: indexp1xXuMkHUDMeta?.path ?? "/admin/survey/:surveyId()/creation",
    meta: indexp1xXuMkHUDMeta || {},
    alias: indexp1xXuMkHUDMeta?.alias || [],
    redirect: indexp1xXuMkHUDMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/index.vue").then(m => m.default || m)
  },
  {
    name: indexh0Ao6e5ubuMeta?.name ?? "admin-survey-surveyId-creation-section-sectionId",
    path: indexh0Ao6e5ubuMeta?.path ?? "/admin/survey/:surveyId()/creation/section/:sectionId()",
    meta: indexh0Ao6e5ubuMeta || {},
    alias: indexh0Ao6e5ubuMeta?.alias || [],
    redirect: indexh0Ao6e5ubuMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/section/[sectionId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgngXBxMe28Meta?.name ?? "admin-survey-surveyId-creation-section-sectionId-question-questionId",
    path: indexgngXBxMe28Meta?.path ?? "/admin/survey/:surveyId()/creation/section/:sectionId()/question/:questionId()",
    meta: indexgngXBxMe28Meta || {},
    alias: indexgngXBxMe28Meta?.alias || [],
    redirect: indexgngXBxMe28Meta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/section/[sectionId]/question/[questionId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91cohortId_93aK5PeDDn0HMeta?.name ?? "admin-survey-surveyId-diffusion-cohorts-cohortId",
    path: _91cohortId_93aK5PeDDn0HMeta?.path ?? "/admin/survey/:surveyId()/diffusion/cohorts/:cohortId()",
    meta: _91cohortId_93aK5PeDDn0HMeta || {},
    alias: _91cohortId_93aK5PeDDn0HMeta?.alias || [],
    redirect: _91cohortId_93aK5PeDDn0HMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/diffusion/cohorts/[cohortId].vue").then(m => m.default || m)
  },
  {
    name: indexzQnQBSAVTLMeta?.name ?? "admin-survey-surveyId-diffusion",
    path: indexzQnQBSAVTLMeta?.path ?? "/admin/survey/:surveyId()/diffusion",
    meta: indexzQnQBSAVTLMeta || {},
    alias: indexzQnQBSAVTLMeta?.alias || [],
    redirect: indexzQnQBSAVTLMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/diffusion/index.vue").then(m => m.default || m)
  },
  {
    name: accessx1duDUATn3Meta?.name ?? "admin-survey-surveyId-results-access",
    path: accessx1duDUATn3Meta?.path ?? "/admin/survey/:surveyId()/results/access",
    meta: accessx1duDUATn3Meta || {},
    alias: accessx1duDUATn3Meta?.alias || [],
    redirect: accessx1duDUATn3Meta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/access.vue").then(m => m.default || m)
  },
  {
    name: indexC4L2vgMRtWMeta?.name ?? "admin-survey-surveyId-results",
    path: indexC4L2vgMRtWMeta?.path ?? "/admin/survey/:surveyId()/results",
    meta: indexC4L2vgMRtWMeta || {},
    alias: indexC4L2vgMRtWMeta?.alias || [],
    redirect: indexC4L2vgMRtWMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/index.vue").then(m => m.default || m)
  },
  {
    name: _91sampleId_93X1ZJSwetITMeta?.name ?? "admin-survey-surveyId-results-samples-edit-sampleId",
    path: _91sampleId_93X1ZJSwetITMeta?.path ?? "/admin/survey/:surveyId()/results/samples/edit/:sampleId()",
    meta: _91sampleId_93X1ZJSwetITMeta || {},
    alias: _91sampleId_93X1ZJSwetITMeta?.alias || [],
    redirect: _91sampleId_93X1ZJSwetITMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/samples/edit/[sampleId].vue").then(m => m.default || m)
  },
  {
    name: indexSbvFyQNjFxMeta?.name ?? "collaborators",
    path: indexSbvFyQNjFxMeta?.path ?? "/collaborators",
    meta: indexSbvFyQNjFxMeta || {},
    alias: indexSbvFyQNjFxMeta?.alias || [],
    redirect: indexSbvFyQNjFxMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/collaborators/index.vue").then(m => m.default || m)
  },
  {
    name: indexHyP0IpxMOrMeta?.name ?? "collaborators-survey-surveyId-results",
    path: indexHyP0IpxMOrMeta?.path ?? "/collaborators/survey/:surveyId()/results",
    meta: indexHyP0IpxMOrMeta || {},
    alias: indexHyP0IpxMOrMeta?.alias || [],
    redirect: indexHyP0IpxMOrMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/collaborators/survey/[surveyId]/results/index.vue").then(m => m.default || m)
  },
  {
    name: _91sampleId_93aw3QFEjiD8Meta?.name ?? "collaborators-survey-surveyId-results-samples-edit-sampleId",
    path: _91sampleId_93aw3QFEjiD8Meta?.path ?? "/collaborators/survey/:surveyId()/results/samples/edit/:sampleId()",
    meta: _91sampleId_93aw3QFEjiD8Meta || {},
    alias: _91sampleId_93aw3QFEjiD8Meta?.alias || [],
    redirect: _91sampleId_93aw3QFEjiD8Meta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/collaborators/survey/[surveyId]/results/samples/edit/[sampleId].vue").then(m => m.default || m)
  },
  {
    name: indexZ5iptvU8XiMeta?.name ?? "index",
    path: indexZ5iptvU8XiMeta?.path ?? "/",
    meta: indexZ5iptvU8XiMeta || {},
    alias: indexZ5iptvU8XiMeta?.alias || [],
    redirect: indexZ5iptvU8XiMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginTyMAxjhfbSMeta?.name ?? "login",
    path: loginTyMAxjhfbSMeta?.path ?? "/login",
    meta: loginTyMAxjhfbSMeta || {},
    alias: loginTyMAxjhfbSMeta?.alias || [],
    redirect: loginTyMAxjhfbSMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexTj3nGIzPR1Meta?.name ?? "survey-surveyId",
    path: indexTj3nGIzPR1Meta?.path ?? "/survey/:surveyId()",
    meta: indexTj3nGIzPR1Meta || {},
    alias: indexTj3nGIzPR1Meta?.alias || [],
    redirect: indexTj3nGIzPR1Meta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/survey/[surveyId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91sectionId_93Um0Uvm7AcRMeta?.name ?? "survey-surveyId-section-sectionId",
    path: _91sectionId_93Um0Uvm7AcRMeta?.path ?? "/survey/:surveyId()/section/:sectionId()",
    meta: _91sectionId_93Um0Uvm7AcRMeta || {},
    alias: _91sectionId_93Um0Uvm7AcRMeta?.alias || [],
    redirect: _91sectionId_93Um0Uvm7AcRMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/survey/[surveyId]/section/[sectionId].vue").then(m => m.default || m)
  },
  {
    name: validationK7bbYw1J0cMeta?.name ?? "survey-surveyId-validation",
    path: validationK7bbYw1J0cMeta?.path ?? "/survey/:surveyId()/validation",
    meta: validationK7bbYw1J0cMeta || {},
    alias: validationK7bbYw1J0cMeta?.alias || [],
    redirect: validationK7bbYw1J0cMeta?.redirect,
    component: () => import("/home/denis/sources/ismat/client/pages/survey/[surveyId]/validation.vue").then(m => m.default || m)
  }
]