//import Proposal from "./Proposal";

export class Section {
    uuid: string | undefined
    title: string
    description: string
    position: number
    survey_uuid: string
    image: string | undefined

    constructor({ uuid, title, description, position, survey_uuid, image }: { uuid?: string, title: string, description: string, position: number, survey_uuid: string, image?:string }) {
        this.uuid = uuid
        this.title = title
        this.description = description
        this.position = position
        this.survey_uuid = survey_uuid
        this.image = image
    }

}
