import {defineStore} from "pinia";
import User from "~/models/User";
import { watch, Ref } from "vue";

export const useUser = defineStore("user", () => {
    const user: Ref<User | null> = ref(null);
    const token: Ref<String | null> = ref(null)
    const pageRole: Ref<String | null> = ref(null)

    return {user, token, pageRole};
})
