import { Question } from "./Question";

import { SurveyStatus } from "./SurveyStatus";
import { Section } from "~/model/Section";

export class Survey {
    uuid: string | undefined;
    title: string;
    status: SurveyStatus;
    company_name: string;
    deadline: Date;
    description_pdf: string;
    description_web: string;
    contact: string;
    sections: Section[]
    logo: string | undefined
    image: string | undefined

    constructor({ uuid, title, description_web, description_pdf, contact, status, sections, company_name, deadline, logo, image }:
        { uuid?: string, title: string, description_web: string, description_pdf: string, contact: string, status: SurveyStatus, sections: Section[], company_name: string, deadline: Date, logo?:string, image?:string }) {

        this.uuid = uuid;
        this.title = title;
        this.description_pdf = description_pdf;
        this.description_web = description_web;
        this.contact = contact;
        this.status = status;
        this.sections = sections;
        this.company_name = company_name;
        this.deadline = deadline;
        this.logo = logo;
        this.image = image;
    }

    getSectionQuestions(section: number): Question[] {
        return this.sections.filter(q => q.section === section)[0].questions
    }

    nbQuestions(section: number): number {
        return this.getSectionQuestions(section).length
    }
}
