import { Validators } from "../Validators";
import Form from "~/utils/form/Form";


export class FormField {
    constructor(
        public key: string,
        public formComponent: string | null,
        public label: string,
        public value: any,
        public validators: Function[],
        public type: string,
        public options: FieldOptions = {},
        public form: Form = new Form()
    ) {
    }

    public errors: string[] = [];
    public repeatMatch = null;
    public hidden = false;

    public reset(): void {
        this.value = null;
        this.errors = [];
    }

    isRequired(): boolean {
        let isRequired = false;
        this.validators.forEach((v): void => {
            if (v === Validators.required) {
                isRequired = true;
            }
        });
        return isRequired;
    }

    public validate(showError = true): void {
        if (showError) {
            this.errors = [];
            if (this.repeatMatch !== null && !this.repeatMatch) {
                this.errors.push("error.repeat");
            }
            if (!this.validators || !this.validators.length) return;
            this.validators.forEach((validator) => {
                if (validator) {
                    const error = validator.call(this, this.value);

                    if (error !== null) {
                        if (!this.errors) {
                            this.errors = [];
                        }
                        this.errors.push(error);
                    }
                }
            });
        }
    }

    hide() {
        this.hidden = true;
    }

    show() {
        this.hidden = false;
    }
}

export enum FieldTypes {
    text = "text",
    date = "date",
    dateTime = "datetime",
    number = "number",
    array = "array",
    file = "file",
    subform = "subform",
    multipleForm = "multipleForm",
    boolean = "boolean",
    color = "color",
}

export interface FieldOptions {
    hint?: string;
    placeholder?: string;
    suffix?: {
        text?: string;
        icon?: string;
    };
    formComponent?: Object;
    sortableField?: string;
    translatedLabel?: boolean;
    repeat?: boolean;
    fields?: [];
    condition?: { field: string; comparator: string; value: any };
    min?: number;
    max?: number;
    step?: number;
    multiple?: boolean;
    disabled?: boolean;
}
