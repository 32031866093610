<template>

  <div class="relative" v-if="props.control">
    <div class="relative">

      <label
          :class="hasValue || focus || control.type == 'date' ? 'text-xs -top-2 font-bold bg-white' : 'text-base top-3'"
          class="absolute transition-all ml-2 px-1 pointer-events-none text-dark"
          :for="control.key"
      >
        {{ $t(control.label) }} <span v-if="control.isRequired()">*</span>
      </label>

      <input
          class="p-3 rounded ring-0 border-2 border-gray-300 focus:ring-0 focus:border-contrast transition-all w-full disabled:text-gray-400"
          v-model="control.value"
          :type="visible ? 'text' : type()"
          :name="control.key"
          :data-cy="`input-${control.key}`"
          :id="control.key"
          @keydown.enter="$emit('submit')"
          @input="debounce"
          @focus="focus = true"
          @focusout="focus = false;control.validate()"
          :disabled="control.options.disabled"
          :label="control.label">
      <div class="h-6">
        <div class="text-xs text-red-500" v-if="control.errors.length > 0">
          {{ $t(control.errors[0]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { ref } from "vue";
import {FieldTypes} from "~/utils/form/fields/FormField";


const emit = defineEmits(['change', 'submit', 'clickSuffix'])
const focus = ref(false)
const visible = ref(false)
let timeout: any = null;

const type = () => {
  if(props.control.type == FieldTypes.date || props.control.type == FieldTypes.dateTime)
  {
    return 'date'
  }
  return props.control.type
}

const props = defineProps({
  control: {
    required: true
  },
  debounce: {
    type: Number,
    default: 0
  },
  displayLabel: {
    default: true
  }
})

function debounce(event: any) {
  let value = event.target.value;
  clearTimeout(timeout)
  timeout = setTimeout((event: Event) => {
    props.control.value = value;
    emit('change', props.control);
  }, props.debounce)
}

const hasValue = computed(() => {
  return (!!props.control.value) || (props.control.value === 0);
})

</script>
