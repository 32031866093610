import Form from "~/utils/form/Form";
import {FieldTypes, FormField} from "~/utils/form/fields/FormField";
import {Validators} from "~/utils/form/Validators";
import {SelectFormField} from "~/utils/form/fields/SelectFormField";
import {useSurveyStore} from "~/stores/SurveyStore";

export default function SampleForm(sample?: Sample): Ref<Form> {

    const visibilityChoices = [
        {
            label: 'public',
            value: 'public'
        },
        {
            label: 'shared',
            value: 'shared'
        }
    ]

    const form = ref(new Form());
    form.value.controls.push(new FormField('title', FieldTypes.text, 'label.title', sample?.title ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new FormField('color', FieldTypes.color, 'label.color', sample?.color ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new SelectFormField('cohort_uuid', FieldTypes.text, 'label.cohort', sample?.cohort_uuid ?? null, [], FieldTypes.text, useSurveyStore().cohortChoices, {}))
    form.value.controls.push(new SelectFormField('visibility', FieldTypes.boolean, 'label.visibility', sample?.visibility ?? false, [], FieldTypes.boolean, visibilityChoices))

    return form;
}
