import type {FormChoice} from "~/utils/form/Form";

export enum SurveyStatus {
    Draft = 'draft',
    Published = 'published',
    Closed = 'closed',
}

export const SurveyStatusChoices: Array<FormChoice> = [
    {
        value: "draft",
        label: "status.draft"
    },
    {
        value: "published",
        label: "status.published"
    },
    {
        value: "closed",
        label: "status.closed"
    }
]

export const SurveyStatusColors = {
    [SurveyStatus.Draft]: 'bg-gray-200',
    [SurveyStatus.Published]: 'bg-green-400',
    [SurveyStatus.Closed]: 'bg-blue-200',
};