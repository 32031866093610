export enum QuestionType {
    reducedScale = "reduced_scale",
    extendedScale = "extended_scale",
    freeForm = "free_form",
    select = "select",
}

export const QuestionTypeLabels = {
    [QuestionType.reducedScale]: "Reduced Scale (1-4)",
    [QuestionType.extendedScale]: "Extended Scale (1-10)",
    [QuestionType.freeForm]: "Free text",
    [QuestionType.select]: "Selector",
}
