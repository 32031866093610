export class Sample {
    uuid: string
    title: string
    color: string
    sample_responses: []

    constructor({ uuid, title, color, sample_responses }: { uuid?: string, title: string, color: string, sample_responses: [] }) {
        this.uuid = uuid
        this.title = title
        this.color = color
        this.sample_responses = sample_responses
    }

}
