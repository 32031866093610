import defaultTheme from "tailwindcss/defaultTheme";
import tailwindColors from "tailwindcss/colors";

/** @type {import('tailwindcss').Config} */
const config = {
	content: [
		'./composables/**/*.{js,vue,ts}',
		'./components/**/*.{js,vue,ts}',
		'./layouts/**/*.vue',
		'./pages/**/*.vue',
		'./plugins/**/*.{js,ts}',
		'./nuxt.config.{js,ts}',
		'./app.vue',
		'./model/**/*.{js,ts}',
	],
	theme: {
		extend: {
			colors: {
				primary: '#0348FF',
				dark:'#2C354E',
				lighttext:'#8C9EB9',
				page: '#F1F4F8',
				light: '#F1F4F8',
				border: '#E5ECFE',
				success : tailwindColors.green["500"],
				danger : tailwindColors.red["500"]
			},
			dropShadow: {
				'primary': '0px 0px 6px #0248FF47',

			}
		},
	}
}

export default config;
