import dayjs from "dayjs";
import { FieldTypes, FormField } from "./fields/FormField";
import { FormFormField } from "./fields/FormFormField";
import { SelectFormField } from "~/utils/form/fields/SelectFormField";

export default class Form {

    public controls: Array<FormField | FormFormField | SelectFormField> = [];
    public errors:Array<any> = [];

    public async isValid() {
        let isValid = this.controls.map(c => {
            c.validate();
            return JSON.parse(JSON.stringify(c.errors)).length <= 0;
        })

        return !isValid.includes(false);
    }


    public getControl(key: string): FormField | FormFormField | SelectFormField {
        return this.controls.filter(c => c.key === key)[0];
    }

    public getRawValues(): any {
        let rawValues: any = {};
        this.controls.map(control => {
            switch (control.type) {
                case null:
                    break;
                case FieldTypes.number:
                    rawValues[control.key] = parseFloat(control.value)
                    break;
                case FieldTypes.date:
                    let date = dayjs(control.value);
                    if (date.isValid()) {
                        rawValues[control.key] = date.format('YYYY-MM-DD');
                    } else {
                        rawValues[control.key] = null
                    }
                    break;
                case FieldTypes.dateTime:
                    let dateTime = dayjs(control.value)
                    if (dateTime.isValid()) {
                        rawValues[control.key] = dateTime.format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        rawValues[control.key] = null
                    }
                    break;
                case FieldTypes.file:
                    if (control) {
                        rawValues[control.key] = control.file;
                    } else {
                        rawValues[control.key] = control.value;
                    }
                    break;
                case FieldTypes.subform:
                    let value: any[] = []
                    let formControl = control;

                    if (formControl) {
                        formControl.forms.map(form => {
                            value.push(form.getRawValues());
                        })

                        rawValues[control.key] = value;
                    }

                    break;
                case FieldTypes.boolean:
                    rawValues[control.key] = control.value ?? false;
                    break;
                default:
                    rawValues[control.key] = control.value
            }
        })
        return rawValues;
    }

    public setValues(values) {
        this.controls.map(control => {


            switch (control.type) {
                case FieldTypes.date:
                case FieldTypes.dateTime:
                    control.value = values[control.key] ? dayjs(values[control.key]) : null;
                    break;
                default:
                    control.value = values[control.key] ?? null;
            }

        })
    }
}

export interface FormChoice{
    value:any,
    label:string
}

